import {StatsigExperimentKey, UseExperimentReturn} from '@posh/model-types'
import {useStatsigClient} from '@statsig/react-bindings'

type UseExperimentParams = {
  experimentKey: StatsigExperimentKey
  enabled?: boolean
}

/**
 * A hook that returns the value of an experiment from statsig.
 * If the experiment is disabled, it returns a value of false.
 * If the experiment is enabled, it returns the value of the experiment.
 * If the experiment is not found, it returns a value of false.
 */
export const useExperiment = ({experimentKey, enabled = true}: UseExperimentParams): UseExperimentReturn => {
  const statsigClient = useStatsigClient()

  // If the experiment check is not enabled, then just return false (which never calls the statsig api yet)
  if (!enabled) {
    return {value: false, name: '', ruleID: ''}
  }

  const {name, value, ruleID} = statsigClient.getExperiment(experimentKey)
  return {value: value.enabled as boolean, name, ruleID}
}
